/** @jsxImportSource @emotion/react */
import { Flex, TextField, Button } from 'ui';
import { css } from '@emotion/react';

const container = css`
  padding: 3rem;
`;

const h3 = css`
  width: 80%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid #e1e1e1;
  text-align: center;
  padding: 2rem 0 1.5rem;
`;

const h4 = css`
  font-size: 1rem;
  line-height: 1.3em;
  font-weight: 600;
  line-height: 2rem;
`;

const h5 = css`
  font-size: 1rem;
  line-height: 1.3em;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 11rem;
`;

const position = css`
  margin-bottom: 3rem;
  &:hover {
    color: #0065b3;
  }
`;

const Join1 = () => (
  <>
    <div css={container}>
      <div css={h3}>이메일 인증번호</div>

      <div css={h5}>
        인증코드가 포함된 이메일이 clara@naver.com (으)로 전송되었습니다.
      </div>
      <div css={position}>
        <div css={h4}>인증코드 입력</div>
        <br />
        <TextField placeholder="이메일을 입력해주세요." />
      </div>

      <Flex>
        <Button>확인</Button>
      </Flex>
    </div>
  </>
);

export default Join1;
