import { useEffect } from 'react';
import { BrowserRouter, Route, useLocation, Redirect } from 'react-router-dom';
import {
  Main,
  Notice,
  NoticeDetail,
  Cheerings,
  Movements,
  MovementDetail,
  Contents,
  ContentDetail,
  News,
  NewsDetail,
  Signin,
  PasswordReset,
  PasswordResetRequest,
  Signup,
  Join1,
  Join2,
  Join3,
  EmailVerification,
  About,
  Info,
  Terms,
  Privacy,
} from 'pages';
import { Menu } from 'ui';
import _ from 'lodash';

const ScrollTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const CheckAuth = () => {
  const location = useLocation();
  const { pathname } = location;

  const privatePaths = ['/encourage', '/movements'];

  const isPrivate = _.chain(privatePaths)
    .map((item) => _.includes(pathname, item))
    .some()
    .value();

  if (pathname !== '/' && isPrivate && !localStorage.getItem('accessToken')) {
    return <Redirect to={`/sign-in?redirect=${pathname}`} />;
  }

  return null;
};

const MainRouter = () => (
  <>
    <BrowserRouter>
      <CheckAuth />
      <ScrollTop />
      <Menu />
      <Route exact path="/">
        <Main />
      </Route>
      <Route exact path="/notices">
        <Notice />
      </Route>
      <Route exact path="/notices/:id">
        <NoticeDetail />
      </Route>
      <Route exact path="/movements">
        <Movements />
      </Route>
      <Route exact path="/movements/:id">
        <MovementDetail />
      </Route>
      <Route exact path="/contents">
        <Contents />
      </Route>
      <Route exact path="/contents/:id">
        <ContentDetail />
      </Route>
      <Route exact path="/news">
        <News />
      </Route>
      <Route exact path="/news/:id">
        <NewsDetail />
      </Route>
      <Route exact path="/encourage">
        <Cheerings />
      </Route>
      <Route exact path="/sign-in">
        <Signin />
      </Route>
      <Route exact path="/reset-password">
        <PasswordReset />
      </Route>
      <Route exact path="/reset-password/request">
        <PasswordResetRequest />
      </Route>
      <Route exact path="/sign-up">
        <Signup />
      </Route>
      <Route exact path="/join1">
        <Join1 />
      </Route>
      <Route exact path="/join2">
        <Join2 />
      </Route>
      <Route exact path="/join3">
        <Join3 />
      </Route>
      <Route exact path="/email-verification">
        <EmailVerification />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
      <Route exact path="/info">
        <Info />
      </Route>
      <Route exact path="/terms">
        <Terms />
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>
    </BrowserRouter>
  </>
);

export default MainRouter;
