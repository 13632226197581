const theme = {
  colors: {
    background: '#f5f5f5',
    primary: '#2071bc',
    primaryHover: '#1D67AC',
    secondary: '#717171',
    border: '#e4e4e4',
  },
  shadow: '0 1px 2px rgba(0, 0, 0, 0.2);',
  radius: '10px',
};

export default theme;
