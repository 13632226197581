/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, useTheme } from '@emotion/react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import {
  Flex,
  Grid,
  VoteCard,
  AnimatedCard,
  Dropdown,
  WidthLimit,
  Margin,
} from 'ui';
import getApiByType from 'misc/getApiByType';

const titleWrapper = css`
  display: flex;
  flex-direction: column;
`;

const titleText = css`
  margin-bottom: 5px;
  font-size: 18pt;
  font-weight: bold;
`;

const subtitleText = (theme) => css`
  font-size: 10pt;
  color: ${theme.colors.secondary};
`;

const dropdownWrapper = css`
  width: 120px;
`;

const CardList = ({
  title,
  subtitle,
  type,
  vote,
  updatedAt,
  descriptionField,
  dateField,
  onClick,
}) => {
  const theme = useTheme();
  const [initial, setInitial] = useState(false);
  const [end, setEnd] = useState(false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('date');
  const [items, setItems] = useState([]);

  const loadNew = async () => {
    const data = await getApiByType(type).get(1, sortBy);
    const newItems = _.chain(data?.results)
      .filter((item) => !_.find(items, { id: item.id }))
      .value();
    setItems([...newItems, ...items]);
  };

  const load = async () => {
    const data = await getApiByType(type).get(page, sortBy);

    const { next } = data?.links;
    if (!next) setEnd(true);
    if (next) setPage(Number(next.split('page=')[1].split('&')[0]));
    else setPage(-1);

    setItems([...items, ...data?.results]);
    setInitial(true);
  };

  const sort = async () => {
    setPage(1);
    setEnd(false);
    const data = await getApiByType(type).get(1, sortBy);

    setItems(data?.results);
  };

  const onBottom = () => {
    if (!end && initial) load();
  };

  const onSortChanged = (i) => {
    if (i === 0) setSortBy('date');
    else if (i === 1) setSortBy('like');
  };

  useBottomScrollListener(onBottom, {
    offset: 0,
    debounce: 500,
    triggerOnNoScroll: true,
  });

  useEffect(() => {
    load();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (initial) sort();
  }, [sortBy]); // eslint-disable-line

  useEffect(() => {
    if (initial) loadNew();
  }, [updatedAt]); // eslint-disable-line

  return (
    <WidthLimit>
      <Flex justify="space-between" align="center">
        <div css={titleWrapper}>
          <div css={titleText}>{title}</div>
          <div css={subtitleText(theme)}>{subtitle}</div>
        </div>
        {vote && (
          <div css={dropdownWrapper}>
            <Dropdown items={['최신순', '추천순']} onChange={onSortChanged} />
          </div>
        )}
      </Flex>

      <Margin size={20} />

      <Grid>
        {_.map(items, (item) =>
          vote ? (
            <VoteCard
              clickable
              key={item.id}
              type={type}
              id={item.id}
              up={item.like_count}
              down={item.unlike_count}
              images={item.images}
              title={item.title || `인하인#${item.user}`}
              description={item[descriptionField]}
              date={item[dateField || 'created_at']}
              hideDown={type !== 'cheerings'}
              onClick={() => onClick(item)}
            />
          ) : (
            <AnimatedCard
              clickable
              key={item.id}
              type={type}
              id={item.id}
              images={item.images}
              title={item.title || `인하인#${item.user}`}
              description={item[descriptionField]}
              date={item[dateField || 'created_at']}
              onClick={() => onClick(item)}
            />
          ),
        )}
      </Grid>
    </WidthLimit>
  );
};

CardList.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  vote: PropTypes.bool,
  updatedAt: PropTypes.instanceOf(Date),
  descriptionField: PropTypes.string.isRequired,
  dateField: PropTypes.string,
  onClick: PropTypes.func,
};

CardList.defaultProps = {
  vote: true,
  updatedAt: null,
  dateField: null,
  onClick: () => {},
};

export default CardList;
