/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import dayjs from 'dayjs';

const wrapper = (theme, clickable) => css`
  background-color: #ffffff;
  border-radius: ${theme.radius};
  box-shadow: ${theme.shadow};
  ${clickable && 'cursor: pointer;'}
`;

const imageWrapper = (theme, image, height) => css`
  height: ${height}px;
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  border-top-left-radius: ${theme.radius};
  border-top-right-radius: ${theme.radius};
`;

const contentWrapper = css`
  word-break: break-all;
  padding: 20px;
`;

const titleWrapper = css`
  margin-bottom: 15px;
  font-size: 15pt;
  font-weight: bold;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const descriptionWrapper = (theme, image) => css`
  margin-bottom: 15px;
  line-height: 1.3;
  color: ${theme.colors.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${image ? 3 : 5};
  -webkit-box-orient: vertical;
`;

const dateWrapper = css``;

const footerWrapper = css``;

const Card = ({
  images,
  title,
  description,
  date,
  footer,
  imageHeight,
  clickable,
  ...props
}) => {
  const theme = useTheme();

  const image = images?.length > 0 ? images[0] : null;

  return (
    <div css={wrapper(theme, clickable)} {...props}>
      {image && <div css={imageWrapper(theme, image, imageHeight)} />}
      <div css={contentWrapper}>
        <div css={titleWrapper}>{title}</div>
        <div css={descriptionWrapper(theme, image)}>{description}</div>
        <div css={dateWrapper}>{dayjs(date).fromNow()}</div>
        <div css={footerWrapper}>{footer}</div>
      </div>
    </div>
  );
};

Card.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  footer: PropTypes.node,
  imageHeight: PropTypes.number,
  clickable: PropTypes.bool,
};

Card.defaultProps = {
  images: [],
  title: '',
  description: '',
  date: new Date(),
  footer: null,
  imageHeight: 170,
  clickable: false,
};

export default Card;
