import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Navigation, WidthLimit, Margin, TextField, Button } from 'ui';
import { useQuery } from 'hooks';
import apis from 'apis';

const PasswordReset = () => {
  const history = useHistory();
  const query = useQuery();

  const email = query.get('email');
  const token = query.get('token');
  const expires = query.get('expires');

  const [password, setPassword] = useState('');

  const onSubmit = async () => {
    try {
      await apis.auth.passwordReset(email, token, expires, password);
    } catch (e) {
      alert(e);
      return;
    }

    alert('비밀번호 변경에 성공하였습니다.');
    history.push('/');
  };

  return (
    <>
      <Navigation />

      <Margin size={80} />

      <WidthLimit>
        <TextField label="이메일" disabled value={email} />
        <Margin size={20} />

        <TextField
          label="비밀번호"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Margin size={20} />

        <Button onClick={onSubmit} disabled={!email || !password}>
          로그인
        </Button>
      </WidthLimit>
    </>
  );
};

export default PasswordReset;
