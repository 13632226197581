/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, keyframes } from '@emotion/react';
import VisibilitySensor from 'react-visibility-sensor';
import { Card } from 'ui';

const fadeIn = keyframes`
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const wrapper = (visible) => css`
  opacity: 0;
  animation-name: ${visible && fadeIn};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
`;

const AnimatedCard = ({ type, id, images, title, description, ...props }) => {
  const [visible, setVisible] = useState(false);
  const [visibleCheck, setVisibleCheck] = useState(false);

  useEffect(() => {
    if (visibleCheck) setVisible(true);
  }, [visibleCheck]);

  const onChange = (isVisible) => {
    setVisibleCheck(isVisible);
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility active={!visible}>
      <div css={wrapper(visible)}>
        <Card
          images={images}
          title={title}
          description={description}
          {...props}
        />
      </div>
    </VisibilitySensor>
  );
};

AnimatedCard.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  description: PropTypes.string,
};

AnimatedCard.defaultProps = {
  type: null,
  id: null,
  images: null,
  title: null,
  description: null,
};

export default AnimatedCard;
