import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import emotionReset from 'emotion-reset';
import { Global, css, ThemeProvider } from '@emotion/react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ko';

import lightTheme from 'themes/light';

import Router from 'routes';

import reportWebVitals from './reportWebVitals';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: '%s 뒤',
    past: '%s 전',
    s: '몇 초',
    m: '1분',
    mm: '%d분',
    h: '1시간',
    hh: '%d시간',
    d: '1일',
    dd: '%d일',
    M: '1달',
    MM: '%d달',
    y: '1년',
    yy: '%d년',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <Global
        styles={css`
          ${emotionReset}

          * {
            outline: none;
            box-sizing: border-box;
            font-family: 'Noto Sans KR', sans-serif !important;
          }

          html,
          body,
          #root {
            height: 100%;
          }

          body {
            background-color: ${lightTheme.colors.background};
          }
        `}
      />
      <RecoilRoot>
        <Router />
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
