/** @jsxImportSource @emotion/react */
import { Navigation, WidthLimit, Margin } from 'ui';
import { css } from '@emotion/react';

const container = css`
  padding: 3rem;
`;

const h2 = css`
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: #0065b3;
`;

const h3 = css`
  width: 90%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid #e1e1e1;
  text-align: center;
  padding: 2rem 0 1.5rem;
`;

const h4 = css`
  font-size: 0.8rem;
  line-height: 1.3em;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 3rem;
`;

const Privacy = () => (
  <>
    <Navigation />

    <Margin size={30} />

    <WidthLimit>
      <div css={container}>
        <div css={h3}>개인정보 처리방침</div>

        <div css={h2}>1. 개인정보 처리방침</div>
        <div css={h4}>
          개인정보 처리방침은 회사가 서비스를 제공함에 있어, 개인정보를 어떻게
          수집, 이용 등에 대한 정보를 담은 방침을 의미합니다. <br />
          개인정보 처리방침은 개인정보보호법, 정보통신망 이용촉진 및 정보보호
          등에 관한 법률 등 국내 개인정보 보호 법령을 모두 준수하고 있습니다.{' '}
          <br />이 약관의 정의는 서비스 이용약관을 따릅니다. <br />
        </div>
        <div css={h2}>2. 익명 시스템</div>
        <div css={h4}>
          익명 시스템이란, 회원가입과 본인 인증 시 이메일과 재적 상태 정보를
          제외한 정보를 일 방향 암호화하여 본인을 확인할 수 없게 하는 보안
          체계를 의미합니다. <br />
          익명 처리된 개인정보는 복원할 수 없으므로, 회원의 개인정보를 제3자에게
          제공하는 것은 원칙적으로 불가능합니다. <br />
        </div>
        <div css={h2}>3. 개인 정보의 수집 및 이용 목적</div>
        <div css={h4}>
          회사는 쾌적한 서비스를 제공하기 위해, 아래의 목적에 한해 개인정보를
          이용합니다
          <br />
          - 가입 시 회원 식별, 재학생 및 졸업생 확인 등 회원 관리
          <br />
          - 서비스 제공 및 기존, 신규 시스템 개발, 유지, 개선
          <br />
          - 불법, 약관 위반 게시물 게시 등 부정행위 방지를 위한 운영 시스템
          개발, 유지, 개선
          <br />
        </div>
        <div css={h2}>4. 쿠키</div>
        <div css={h4}>
          쿠키란, 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는
          아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다.{' '}
          <br />
          서비스는 사이트 로그인을 위해 아이디 식별에 사용할 수 있습니다.
          <br />
          <br />
          [ 쿠키 설정 거부 방법 예시 ] <br />
          - Internet Explorer : 웹 브라우저 상단의 도구 - 인터넷 옵션 - 개인정보
          - 설정 <br />
          - Chrome : 웹 브라우저 우측의 설정 - 고급 설정 표시 - 개인정보의
          콘텐츠 설정 버튼 - 쿠키 <br />
        </div>
        <br />
        <div css={h4}>초안 2021.08.19</div>
      </div>
    </WidthLimit>
  </>
);

export default Privacy;
