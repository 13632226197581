/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Margin } from 'ui';

const wrapper = (itemWidth, loading) => css`
  display: flex;
  overflow-x: scroll;
  flex-wrap: nowrap;
  padding: 0 2px 10px 2px;

  @media (min-width: 575.98px) {
    ::-webkit-scrollbar {
      width: 15px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1); /* 스크롤바 둥글게 설정 */
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  & > div {
    margin-right: 10px;
    flex: 0 0 auto;
    ${!loading && `flex-basis: ${itemWidth}px;`}
  }
`;

const Loader = () => (
  <SkeletonTheme color="#e9ecef" highlightColor="#ced4da">
    <Skeleton width={250} height={230} />
  </SkeletonTheme>
);

const Rail = ({ itemWidth, children }) => {
  const loading = children.length === 0;

  return (
    <>
      <div css={wrapper(itemWidth, loading)}>
        {loading ? (
          <>
            {Array.from(Array(5)).map((i) => (
              <Loader key={i} />
            ))}
          </>
        ) : (
          children
        )}
      </div>
      <Margin size={40} />
    </>
  );
};

Rail.propTypes = {
  itemWidth: PropTypes.number,
  children: PropTypes.node,
};

Rail.defaultProps = {
  itemWidth: 240,
  children: null,
};

export default Rail;
