/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import {
  FaRegThumbsUp,
  FaRegThumbsDown,
  FaThumbsUp,
  FaThumbsDown,
} from 'react-icons/fa';

const wrapper = (size) => css`
  display: flex;
  justify-content: flex-end;
  height: ${size}px;
`;

const button = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12pt;

  &:first-child {
    margin-right: 10px;
  }

  & > svg {
    margin-right: 5px;
  }
`;

const Vote = ({
  up,
  down,
  upActivated,
  downActivated,
  hideDown,
  onUpClick,
  onDownClick,
}) => {
  const onClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div css={wrapper} onClick={onClick}>
      <button css={button} onClick={onUpClick}>
        {upActivated ? <FaThumbsUp /> : <FaRegThumbsUp />} {up}
      </button>
      {!hideDown && (
        <button css={button} onClick={onDownClick}>
          {downActivated ? <FaThumbsDown /> : <FaRegThumbsDown />} {down}
        </button>
      )}
    </div>
  );
};

Vote.propTypes = {
  up: PropTypes.number,
  down: PropTypes.number,
  upActivated: PropTypes.bool,
  downActivated: PropTypes.bool,
  hideDown: PropTypes.bool,
  onUpClick: PropTypes.func,
  onDownClick: PropTypes.func,
};

Vote.defaultProps = {
  up: 0,
  down: 0,
  upActivated: false,
  downActivated: false,
  hideDown: false,
  onUpClick: () => {},
  onDownClick: () => {},
};

export default Vote;
