import { useHistory } from 'react-router-dom';
import { Navigation, CardList, Margin } from 'ui';

const Contents = () => {
  const history = useHistory();

  const onClick = (item) => {
    history.push(`/contents/${item.id}`);
  };

  return (
    <>
      <Navigation />

      <Margin size={80} />

      <CardList
        title="주목 받고 있는 콘텐츠"
        subtitle="카드뉴스, 영상 등의 홍보물"
        descriptionField="body"
        type="contents"
        onClick={onClick}
      />

      <Margin size={110} />
    </>
  );
};

export default Contents;
