/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import { Flex, Margin } from 'ui';

const title = css`
  font-size: 17pt;
  font-weight: bold;
`;

const more = (theme) => css`
  color: ${theme.colors.secondary};
  cursor: pointer;
`;

const Title = ({ morePath, children }) => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <>
      <Flex justify="space-between" align="flex-end">
        <h3 css={title}>{children}</h3>
        <div css={more(theme)} onClick={() => history.push(morePath)}>
          더보기
        </div>
      </Flex>

      <Margin size={15} />
    </>
  );
};

Title.propTypes = {
  morePath: PropTypes.string,
  children: PropTypes.string,
};

Title.defaultProps = {
  morePath: '/',
  children: null,
};

export default Title;
