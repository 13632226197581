import axios from 'axios';

const signIn = async (email, password) => {
  const { data } = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/account/login`,
    data: {
      email,
      password,
    },
  });

  if (data?.error) throw data.error;

  const { access, refresh } = data;
  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
};

const signUp = async (email, password, type) =>
  (
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/account/signup`,
      data: {
        email,
        password,
        type,
      },
    })
  ).data;

const emailVerificate = async (email, token) =>
  (
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/account/email`,
      data: {
        email,
        token,
      },
    })
  ).data;

const passwordResetRequest = async (email) =>
  (
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/account/request-reset`,
      data: { email },
    })
  ).data;

const passwordReset = async (email, token, expires, password) =>
  (
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/account/reset`,
      data: { email, token, expires, password },
    })
  ).data;

export default {
  signIn,
  signUp,
  emailVerificate,
  passwordResetRequest,
  passwordReset,
};
