/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const flex = (justify, align, direction) => css`
  display: flex;
  justify-content: ${justify};
  align-items: ${align};
  flex-direction: ${direction};
`;

const Flex = ({ justify, align, direction, children }) => (
  <div css={flex(justify, align, direction)}>{children}</div>
);

Flex.propTypes = {
  justify: PropTypes.string,
  align: PropTypes.string,
  direction: PropTypes.string,
  children: PropTypes.node,
};

Flex.defaultProps = {
  justify: 'flex-start',
  align: 'flex-start',
  direction: 'row',
  children: null,
};

export default Flex;
