/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { css, useTheme } from '@emotion/react';
import AnimatedNumber from 'animated-number-react';
import { WidthLimit, Flex, Margin } from 'ui';
import apis from 'apis';

import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';

const images = [img1, img2, img3, img4, img5, img6];
const idx = _.random(5);
const image = images[idx];

const wrapper = css`
  position: relative;
  width: 100%;
  height: 500px;
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  color: #ffffff;
`;

const dimmer = css`
  height: 100%;
  padding: 14px 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.9);

  & > div,
  & > div > div {
    height: 100%;
  }

  & > div > div > div {
    width: 100%;
  }
`;

const title = css`
  margin-bottom: 6px;
  font-size: 30pt;
  font-weight: bold;
`;

const subtitle = (theme) => css`
  font-size: 15pt;
  font-weight: lighter;
  letter-spacing: 4px;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    letter-spacing: 9px;
    color: ${theme.colors.primary};
  }
`;

const countWrapper = css`
  margin: 0 auto;
  max-width: 350px;
  height: 100%;
`;

const count = css`
  flex-basis: 80px;
`;

const divider = css`
  height: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.35);
`;

const countTitle = css`
  margin-bottom: 10px;
  font-size: 10pt;
  line-height: 1.4;
`;

const countNumber = css`
  font-size: 13pt;
  letter-spacing: -1px;
`;

const Header = () => {
  const theme = useTheme();
  const [counts, setCounts] = useState([0, 0, 0, 0, 0]);
  const formatNumber = (value) => Number(value.toFixed(0)).toLocaleString();

  useEffect(() => {
    apis.main
      .get()
      .then((data) =>
        setCounts([
          data.total_instagram_tag,
          data.total_tweet,
          data.total_news,
          data.total_user,
          data.total_online_uniform,
        ]),
      );
  }, []);

  return (
    <div css={wrapper}>
      <div css={dimmer}>
        <WidthLimit>
          <Flex justify="center" align="center">
            <div>
              <h1 css={title}>SAVE INHA</h1>
              <h2
                css={subtitle(theme)}
                onClick={() =>
                  window.open(
                    'https://www.instagram.com/explore/tags/%EB%B3%80%ED%99%94%EB%8A%94%EC%9A%B0%EB%A6%AC%EB%A1%9C%EC%9D%B8%ED%95%98%EC%97%AC/',
                  )
                }
              >
                #변화는우리로인하여
              </h2>

              <Margin size={50} />

              <div css={countWrapper}>
                <Flex justify="space-around" align="center">
                  <div css={count}>
                    <div css={countTitle}>
                      과잠 시위
                      <br />
                      제출 현황
                    </div>
                    <div css={countNumber}>
                      <AnimatedNumber
                        value={counts[0]}
                        formatValue={formatNumber}
                      />
                      개
                    </div>
                  </div>

                  <div css={divider} />

                  <div
                    css={count}
                    onClick={() =>
                      window.open('https://uniform.saveinha.com/create')
                    }
                  >
                    <div css={countTitle}>
                      온라인
                      <br />
                      과잠시위
                    </div>
                    <div css={countNumber}>
                      <AnimatedNumber
                        value={counts[4]}
                        formatValue={formatNumber}
                      />
                      회
                    </div>
                  </div>
                </Flex>

                <Margin size={20} />

                <Flex justify="space-between" align="center">
                  <div css={count}>
                    <div css={countTitle}>보도 자료</div>
                    <div css={countNumber}>
                      <AnimatedNumber
                        value={counts[2]}
                        formatValue={formatNumber}
                      />
                      개
                    </div>
                  </div>

                  <div css={divider} />

                  <div css={count}>
                    <div css={countTitle}>응원</div>
                    <div css={countNumber}>
                      <AnimatedNumber
                        value={counts[1]}
                        formatValue={formatNumber}
                      />
                      회
                    </div>
                  </div>

                  <div css={divider} />

                  <div css={count}>
                    <div css={countTitle}>함께하는</div>
                    <div css={countNumber}>
                      <AnimatedNumber
                        value={counts[3]}
                        formatValue={formatNumber}
                      />
                      명
                    </div>
                  </div>
                </Flex>
              </div>
            </div>
          </Flex>
        </WidthLimit>
      </div>
    </div>
  );
};

export default Header;
