/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';

const button = (theme, noRound) => css`
  width: 100%;
  padding: 14px 20px;
  border-radius: ${noRound ? 0 : theme.radius};
  background-color: ${theme.colors.primary};
  border: none;
  box-shadow: ${theme.shadow};
  font-size: 12pt;
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s background-color;

  &:hover {
    background-color: ${theme.colors.primaryHover};
  }

  &:disabled {
    background-color: #adb5bd;
    color: #343a40;
    cursor: not-allowed;
  }
`;

const Button = ({ noRound, children, ...props }) => {
  const theme = useTheme();

  return (
    <button css={button(theme, noRound)} {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  noRound: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  noRound: false,
  children: null,
};

export default Button;
