/** @jsxImportSource @emotion/react */
import { Flex, Button } from 'ui';
import { css } from '@emotion/react';

const container = css`
  padding: 3rem;
`;

const h3 = css`
  width: 80%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid #e1e1e1;
  text-align: center;
  padding: 2rem 0 1.5rem;
`;

// const h4 = css`
//   font-size: 1rem;
//   line-height: 1.3em;
//   font-weight: 600;
//   line-height: 2rem;
// `;

const h5 = css`
  font-size: 1rem;
  line-height: 1.3em;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 20rem;
`;

const Signup = () => (
  <>
    <div css={container}>
      <div css={h3}>인증 완료</div>

      <div css={h5}>
        인증 완료되었습니다. 이용약관 읽어보셈 우리 모두 파이팅
      </div>

      <Flex>
        <Button>세이브인하 시작하기</Button>
      </Flex>
    </div>
  </>
);

export default Signup;
