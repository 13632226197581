/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { Flex, WidthLimit } from 'ui';

const wrapper = (theme) => css`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 20px 0px;
  background-color: #ffffff;
  box-shadow: ${theme.shadow};
  z-index: 995;

  & > div > div,
  & > div > div > div {
    flex-grow: 1;
  }

  & button {
    margin-left: 10px;
    flex-basis: 80px;
  }
`;

const Fixed = ({ children }) => {
  const theme = useTheme();
  return (
    <div css={wrapper(theme)}>
      <WidthLimit>
        <Flex>{children}</Flex>
      </WidthLimit>
    </div>
  );
};

Fixed.propTypes = {
  children: PropTypes.node,
};

Fixed.defaultProps = {
  children: null,
};

export default Fixed;
