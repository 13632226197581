import { useState } from 'react';
import { Navigation, Margin, Fixed, TextField, CardList, Button } from 'ui';
import apis from 'apis';

const Cheerings = () => {
  const [comment, setComment] = useState('');
  const [updatedAt, setUpdatedAt] = useState(new Date());

  const onSubmit = async () => {
    await apis.cheerings.post(comment);
    setUpdatedAt(new Date());
    setComment('');
    window.scrollTo(0, 0);
  };

  const onCommentChange = (e) => {
    const { value } = e.target;
    if (value.length <= 140) setComment(value);
  };

  return (
    <>
      <Navigation />

      <Fixed>
        <TextField
          placeholder="응원의 한마디를 적어주세요."
          value={comment}
          onChange={onCommentChange}
        />
        <Button onClick={onSubmit} disabled={!comment}>
          등록
        </Button>
      </Fixed>

      <Margin size={80} />

      <CardList
        title="응원의 한마디"
        subtitle="#변화는우리로인하여"
        type="cheerings"
        descriptionField="comment"
        updatedAt={updatedAt}
      />

      <Margin size={110} />
    </>
  );
};

export default Cheerings;
