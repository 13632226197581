/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Button, Margin, Label, Dropdown, Navigation } from 'ui';
import { css } from '@emotion/react';

import apis from 'apis';
import _ from 'lodash';

const container = css`
  padding: 3rem;
`;

const h3 = css`
  width: 80%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid #e1e1e1;
  text-align: center;
  padding: 2rem 0 1.5rem;
`;

const h4 = css`
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  text-align: center;
  background-color: #e1e1e1;
  margin-bottom: 5rem;
  border-radius: 10px;
`;

const h5 = css`
  font-size: 1rem;
  line-height: 1.3em;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 4rem;
`;

const Join1 = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [edu, setEdu] = useState(false);
  const [type, setType] = useState('A');

  const onClick = async () => {
    try {
      let finalType = null;
      if (!edu) finalType = 'A';
      else finalType = type;

      await apis.auth.signUp(email, password, finalType);
    } catch (e) {
      alert(e?.response?.data?.error);
      return;
    }

    alert('인증 메일이 전송되었습니다. 메일함을 확인해주세요.');
    history.push('/');
  };

  useEffect(() => {
    const isEdu = _.includes(email, '@inha.edu');
    setEdu(isEdu);
  }, [email]);

  return (
    <>
      <Navigation />
      <div css={container}>
        <div css={h3}>회원가입</div>

        <div css={h5}>입력한 이메일로 인증 후 가입이 됩니다.</div>
        <div css={h4}>
          교직원 분들 이메일은 @inha.ac.kr <br />
          학우분들은 @inha.edu로 가입 부탁드립니다.
        </div>
        <TextField
          label="이메일"
          placeholder="이메일을 입력해주세요."
          onChange={(e) => setEmail(e.target.value)}
        />
        {edu && (
          <>
            <Margin size={20} />
            <Label> 신분 </Label>
            <Dropdown
              items={['재학생', '졸업생']}
              onChange={(i) => setType(i === 0 ? 'A' : 'B')}
            />
          </>
        )}
        <Margin size={20} />
        <TextField
          type="password"
          label="패스워드"
          placeholder="패스워드를 입력해주세요."
          onChange={(e) => setPassword(e.target.value)}
        />
        <Margin size={20} />
        <Button onClick={onClick} disabled={!email || !password}>
          회원가입
        </Button>
      </div>
    </>
  );
};

export default Join1;
