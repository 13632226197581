/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const wrapper = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 575.98px) {
    grid-template-columns: 1fr;
  }
`;

const WidthLimit = ({ children }) => <div css={wrapper}>{children}</div>;

WidthLimit.propTypes = {
  children: PropTypes.node,
};

WidthLimit.defaultProps = {
  children: null,
};

export default WidthLimit;
