/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { Label } from 'ui';

const input = (theme) => css`
  width: 100%;
  padding: 14px 20px;
  border-radius: 10px;
  border: 1px solid ${theme.colors.border};
  box-shadow: ${theme.shadow};
  font-size: 12pt;
`;

const TextField = ({ label, multiline, ...props }) => {
  const theme = useTheme();

  return (
    <div>
      {label && <Label>{label}</Label>}
      {multiline ? (
        <textarea css={input(theme)} {...props} />
      ) : (
        <input css={input(theme)} {...props} />
      )}
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  multiline: PropTypes.bool,
};

TextField.defaultProps = { label: null, multiline: false };

export default TextField;
