// import { useHistory } from 'react-router-dom';
import { Navigation, CardList, Margin } from 'ui';

const News = () => {
  // const history = useHistory();

  const onClick = (item) => {
    window.open(item.link);
    // history.push(`/news/${item.id}`);
  };

  return (
    <>
      <Navigation />

      <Margin size={80} />

      <CardList
        title="실시간 뉴스"
        subtitle="현 상황에 대한 실시간 뉴스"
        descriptionField="article"
        dateField="pub_date"
        type="news"
        onClick={onClick}
      />

      <Margin size={110} />
    </>
  );
};

export default News;
