/** @jsxImportSource @emotion/react */
import { Navigation, WidthLimit, Margin } from 'ui';
import { css } from '@emotion/react';

const container = css`
  padding: 3rem;
`;

const h2 = css`
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: #0065b3;
`;

const h3 = css`
  width: 90%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid #e1e1e1;
  text-align: center;
  padding: 2rem 0 1.5rem;
`;

const h4 = css`
  font-size: 0.8rem;
  line-height: 1.3em;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 3rem;
`;

const About = () => (
  <>
    <Navigation />

    <Margin size={30} />

    <WidthLimit>
      <div css={container}>
        <div css={h3}>서비스 소개</div>
        <div css={h4}>
          현재 본교, 인하대학교가 2021년 대학기본역량진단평가 결과에서 미선정된
          건에 대해 온라인 커뮤니티뿐만 아니라 각종 언론에서도 커다란 이슈가
          되고 있습니다.
          <br />
          인하대학교를 지키기 위해 지금 이 순간에는, 짧은 기간 동안 여러
          학우들의 수많은 인증과 챌린지, 정보들이 넘쳐나고 있습니다.
          <br />
          불타는 열정과 학교를 지키려는 마음으로 가득 찬 학우들이 하나로
          단합되는 것은 쉽지 않은 일이지만, 지금 이 순간 우리들은 해내고
          있습니다.
        </div>
        <div css={h2}>#변화는_우리로_인하여</div>
        <div css={h4}>
          저희는 이러한 모습을 보고만 있을 수 없기 때문에 <br />
          세이브 인하라는 서비스로 조금이라도 학교에 도움이 되고 싶었습니다.
        </div>
        <div css={h3}>세이브 인하, 어떤 서비스인가</div>
        <div css={h4}>
          수많은 정보의 범람, 많은 인증 챌린지의 진행. <br />이 모든 것을 한눈에
          확인할 수 있는 포털형 웹 사이트입니다. <br />
          <br />
          민원이나 SNS 챌린지 릴레이 등 인증할 수 있는 서비스, <br />
          모두에게 응원의 한 마디를 남기는 서비스, <br />
          각종 자료들을 담은 아카이브 서비스를 제공합니다. <br />
          <br />
          추후에 더 많은 기능들을 추가해나가려고 합니다. <br />
          서비스 기능에 대한 자세한 설명은 서비스 사용 안내서를 참고해 주세요
        </div>
        <div css={h3}>마지막으로 부탁 한 마디</div>
        <div css={h4}>
          여러 동기와 후배들, 그리고 졸업하고 사회에 계신 선배분들까지. <br />
          지금 인하대학교 학우들은 다 같이 힘을 합쳐 현재 상황을 이겨내고
          합니다.
          <br />
          <br />
          우리는 억지나 감정적인 호소가 아니라, 여러 지표와 소리를 통해서
          객관적으로 바라보며 이 부당한 상황을 타파하려고 합니다. <br />
          세이브인하를 통해서 흩어진 수많은 정보를 모으고 마음을 담아서 학교에게
          더 큰 힘이 되기를 바라며, <br />
          서비스를 이용하는 학우분들도 이번 일에 대해 많은 관심을 갖고 더욱
          응원하겠습니다. <br />
          <br />
          기본적이면서도 당연한 일이지만, 해당 서비스를 본래 목적에 맞게
          사용하도록 부탁드리며 <br />
          우리가 쏘아 올리는 화살이 다른 곳에 대한 비방과 비하의 목적으로
          변질되지 않길 바라며 자랑스럽고 명예로운 인하대학교 일원임을 모두
          기억합시다.
          <br />
          서비스 관련 자세한 사항은 세이브 인하 이용약관을 참고해 주세요.
          <br />
          <br />
          우리는 인하대학교 일원으로서 정보를 한 곳에 기록(save)하고 인하대를
          지켜냅시다.
        </div>
        <div css={h2}>#변화는_우리로_인하여</div>
      </div>
    </WidthLimit>
  </>
);

export default About;
