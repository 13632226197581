import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatedCard } from 'ui';
import getApiByType from 'misc/getApiByType';

import Vote from './components/Vote';

const VoteCard = ({ type, id, up, down, clickable, hideDown, ...props }) => {
  const [count, setCount] = useState([up, down]);
  const [voteActivated, setVoteActivated] = useState([false, false]);

  useEffect(() => {
    getApiByType(type)
      .getVoteActivated(id)
      .then((data) => {
        const { like, unlike } = data;
        setVoteActivated([like, unlike]);
      });
  }, [id, type, count]);

  const onUpClick = async () => {
    if (!clickable) return;

    try {
      const { like, unlike } = await getApiByType(type).voteUp(id);
      setCount([like, unlike]);
    } catch (e) {
      if (e.response.status === 403) alert('로그인이 필요합니다.');
    }
  };

  const onDownClick = async () => {
    if (!clickable) return;

    try {
      const { like, unlike } = await getApiByType(type).voteDown(id);
      setCount([like, unlike]);
    } catch (e) {
      if (e.response.status === 403) alert('로그인이 필요합니다.');
    }
  };

  return (
    <>
      <AnimatedCard
        clickable={clickable}
        footer={
          <Vote
            up={count[0]}
            down={count[1]}
            upActivated={voteActivated[0]}
            downActivated={voteActivated[1]}
            onUpClick={onUpClick}
            onDownClick={onDownClick}
            hideDown={hideDown}
          />
        }
        {...props}
      />
    </>
  );
};

VoteCard.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  up: PropTypes.number,
  down: PropTypes.number,
  hideDown: PropTypes.bool,
  clickable: PropTypes.bool,
};

VoteCard.defaultProps = {
  up: 0,
  down: 0,
  hideDown: false,
  clickable: true,
};

export default VoteCard;
