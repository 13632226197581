import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Navigation, WidthLimit, Margin, TextField, Button } from 'ui';
import { useQuery } from 'hooks';
import apis from 'apis';

const Complaints = () => {
  const history = useHistory();
  const query = useQuery();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = async () => {
    try {
      await apis.auth.signIn(email, password);
    } catch (e) {
      alert(e);
      return;
    }

    const redirect = query.get('redirect');

    alert('로그인에 성공하였습니다.');
    if (redirect) history.push(`${redirect}`);
    else history.push('/');
  };

  return (
    <>
      <Navigation />

      <Margin size={80} />

      <WidthLimit>
        <TextField label="이메일" onChange={(e) => setEmail(e.target.value)} />
        <Margin size={20} />

        <TextField
          label="비밀번호"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Margin size={20} />

        <Button onClick={onSubmit} disabled={!email || !password}>
          로그인
        </Button>
      </WidthLimit>
    </>
  );
};

export default Complaints;
