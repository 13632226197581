/** @jsxImportSource @emotion/react */
import { Navigation, WidthLimit, Margin } from 'ui';
import { css } from '@emotion/react';

const container = css`
  padding: 3rem;
`;

const h2 = css`
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const h3 = css`
  width: 90%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid #e1e1e1;
  text-align: center;
  padding: 2rem 0 1.5rem;
`;

const h4 = css`
  font-size: 0.8rem;
  line-height: 1.3em;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 5rem;
`;

const Terms = () => (
  <>
    <Navigation />

    <Margin size={30} />

    <WidthLimit>
      <div css={container}>
        <div css={h3}>이용약관</div>
        <div css={h2}>제 1조 목적</div>
        <div css={h4}>
          세이브 인하 서비스 이용 약관(이하 약관이라고 합니다)은 앱 혹은 웹
          사이트, 그리고 이 앱 혹은 웹 사이트에 연결되는 사이트와 부속 사이트와
          이를 통해 제공되는 서비스를 이용함에 있어 회사와 이용자 간의 권리와
          의무 및 책임 사항 등을 규정함을 목적으로 합니다.
        </div>
        <div css={h2}>제 2조 정의</div>
        <div css={h4}>
          1. 회사란, 서비스를 제공하는 주체를 말합니다. <br />
          2. 서비스란, 회사가 제공하는 모든 서비스 및 기능을 말합니다.
          <br />
          3. 이용자란, 이 약관에 따라 서비스를 이용하는 자를 말합니다.
          <br />
          4. 회원이란, 서비스에 회원 등록을 하고 서비스를 이용하는 자를
          말합니다. <br />
          5. 게시물이란, 서비스에 게재된 문자, 사진, 영상, 첨부파일, 광고 등을
          말합니다. <br />
          6. 이용 기록이란, 이용자가 서비스를 이용하면서 직접 생성한 게시물 등을
          말합니다. <br />
          7. 로그 기록이란, 이용자가 서비스를 이용하면서 자동으로 생성된 IP
          주소, 접속 시간 등을 말합니다. <br />
          8. 기기 정보란, 이용자의 통신 기기에서 수집된 운영체제의 종류, ADID
          등을 말합니다. <br />
          9. 계정이란, 이용계약을 통해 생성된 회원의 고유 아이디와 이에 수반하는
          정보를 말합니다. <br />
          10. 관련 법이란, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률,
          개인정보보호법, 통신비밀 보호법 등 관련 있는 국내 법령을 말합니다.
          <br />
          11. 본인 인증이란, 이메일과 증명서 등을 이용한 본인과 학적 확인 절차를
          말합니다.
        </div>
        <div css={h2}>제 3조 약관의 게시와 개정</div>
        <div css={h4}>
          1. 회사는 약관의 내용을 이용자가 알 수 있도록 로그인 화면 및 메뉴 등에
          게시합니다.
          <br />
          2. 회사는 약관의 규제에 관한 법률, 전자상거래 등에서의 소비자보호에
          관한 법률, 소비자기본법 등 관련 법규를 위배하지 않는 범위에서 약관의
          일부를 변경, 수정, 추가, 삭제할 수 있습니다.
          <br />
          3. 회사가 약관을 개정할 경우에는 적용일자를 약관의 하단에 명시하고,
          사이트 초기 화면의 공지사항 혹은 그에 준하는 게시판에 공지합니다.{' '}
          <br />
          4. 약관은 공지된 즉시 발효되며, 서비스를 이용한다는 것은 약관에
          동의한다는 것을 의미합니다. <br />
          5. 회원은 약관 일부분만을 동의 또는 거부할 수 없습니다. <br />
          6. 비회원이 서비스를 이용할 경우, 이 약관에 동의한 것으로 간주합니다.
        </div>
        <div css={h2}>제 4조 서비스의 제공</div>
        <div css={h4}>
          1. 회사는 운영상, 기술상의 필요에 따라 제공하고 있는 서비스를 변경할
          수 있습니다. <br />
          2. 회사는 이용자의 개인 정보 및 서비스 이용 기록에 따라 서비스 이용에
          차이를 둘 수 있습니다. <br />
          3. 회사는 천재지변, 인터넷 장애, 경영 악화 등으로 인해 서비스를 더
          이상 제공하기 어려울 경우, 서비스를 통보 없이 중단할 수 있습니다.{' '}
          <br />
          4. 회사는 1항부터 전항까지와 다음 내용으로 인해 발생한 피해에 대한
          어떠한 책임을 지지 않습니다. <br />- 모든 서비스, 게시물, 이용 기록의
          진본성, 무결성, 신뢰성, 이용 가능성 <br />
          - 게시물, 광고의 버튼, 하이퍼링크 등 외부로 연결된 서비스와 같이
          회사가 제공하지 않은 서비스에서 발생한 피해 <br />
          - 이용자의 귀책사유 또는 회사의 귀책사유가 아닌 사유로 발생한 이용자의
          피해 <br />
        </div>
        <div css={h2}>제 5조 서비스 이용계약의 성립</div>
        <div css={h4}>
          세이브 인하는 이메일, 증명서를 통해 인하대학교 재학생 및 졸업생 또는
          해당 연도 입학생으로 인증받은 이용자에 한해 이용할 수 있는
          서비스입니다. <br />
          인하대학교의 학생이 아닌 이용자는 서비스를 이용하거나 회원가입을 할 수
          없으며, 그럼에도 불구하고 성립된 회원가입은 무효로 간주됩니다
        </div>
        <div css={h2}>제 6조 개인 정보의 관리 및 보호</div>
        <div css={h4}>
          회원이 회사와 체결한 서비스 이용계약은 처음 이용계약을 체결한 본인에
          한해 적용됩니다.
        </div>
        <div css={h2}>제 7조 금지행위</div>
        <div css={h4}>
          1. 이용자는 다음과 같은 행위를 해서는 안 됩니다. <br />
          * 개인정보 또는 계정 기만, 침해, 공유 행위
          <br />
          - 개인정보를 허위, 누락, 오기, 도용하여 작성하는 행위
          <br />
          - 타인의 개인정보 및 계정을 수집, 저장, 공개, 이용하는 행위
          <br />
          - 자신과 타인의 개인정보를 제3자에게 공개, 양도하는 행위
          <br />
          - 다중 계정을 생성 및 이용하는 행위
          <br />
          - 자신의 계정을 이용하여 타인의 요청을 이행하는 행위
          <br />
          <br />
          * 시스템 부정행위
          <br />
          - 인하대학교의 재학생 및 졸업생이 아닌 이용자가 서비스를 이용하는 행위
          <br />
          - 허가하지 않은 방식의 서비스 이용 행위
          <br />
          - 회사의 모든 재산에 대한 침해 행위
          <br />
          <br />
          * 업무 방해 행위
          <br />
          - 서비스 관리자 또는 이에 준하는 자격을 사칭하거나 허가없이 취득하여
          직권을 행사하는 행위
          <br />
          - 회사 및 타인의 명예를 손상시키거나 업무를 방해하는 행위
          <br />
          - 서비스 내부 정보 일체를 허가 없이 이용, 변조, 삭제 및 외부로
          유출하는 행위
          <br />
          <br />
          2. 이 약관, 개인정보 처리방침, 서비스 이용규칙에서 이행 및 비행을
          명시한 내용에 반하는 행위
          <br />
          3. 기타 현행법에 어긋나거나 부적절하다고 판단되는 행위
        </div>
        <br />
        <div css={h4}>초안 2021.08.19</div>
      </div>
    </WidthLimit>
  </>
);

export default Terms;
