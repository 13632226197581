import apis from 'apis';

// TODO: API 업데이트 되면 수정
export default (type) => {
  if (type === 'cheerings') return apis.cheerings;
  if (type === 'notices') return apis.notices;
  if (type === 'movements') return apis.movements;
  if (type === 'contents') return apis.contents;
  if (type === 'news') return apis.news;
  return null;
};
