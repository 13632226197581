/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const labelWrapper = css`
  margin-bottom: 5px;
  font-size: 11pt;
  font-weight: bold;
`;

const Label = ({ children }) => <div css={labelWrapper}>{children}</div>;

Label.propTypes = {
  children: PropTypes.string,
};

Label.defaultProps = {
  children: '',
};

export default Label;
