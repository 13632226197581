/** @jsxImportSource @emotion/react */
import { Navigation, WidthLimit, Margin } from 'ui';
import { css } from '@emotion/react';

const container = css`
  padding: 3rem;
`;

const h3 = css`
  width: 90%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid #e1e1e1;
  text-align: center;
  padding: 2rem 0 1.5rem;
`;

// const h4 = css`
//   font-size: 0.8rem;
//   line-height: 1.3em;
//   font-weight: 500;
//   line-height: 2rem;
//   margin-bottom: 3rem;
// `;

const Info = () => (
  <>
    <Navigation />

    <Margin size={30} />

    <WidthLimit>
      <div css={container}>
        <div css={h3}>서비스 안내</div>
        <div> 작성중 입니다. </div>
      </div>
    </WidthLimit>
  </>
);

export default Info;
