/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { GrDown } from 'react-icons/gr';

const wrapper = (theme, width) => css`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 15px;
  width: ${width ? `${width}px` : '100%'};
  background-color: #ffffff;
  border-radius: ${theme.radius};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: ${theme.shadow};
  cursor: pointer;

  & > svg {
    margin-right: 10px;
  }

  & > ul {
    width: ${width ? `${width}px` : '100%'};
  }
`;

const menu = (theme) => css`
  position: absolute;
  top: 55px;
  right: 0;
  padding: 5px 0;
  background-color: #ffffff;
  border-radius: ${theme.radius};
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: ${theme.shadow};
  z-index: 994;

  & > li {
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    cursor: pointer;
    transition: 0.3s background-color;
  }

  & > li:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Dropdown = ({ width, items, onChange }) => {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState('');
  const theme = useTheme();

  useEffect(() => {
    setLabel(items[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = (i) => {
    setLabel(items[i]);
    onChange(i);
  };

  return (
    <div
      css={wrapper(theme, width)}
      onChange={onChange}
      onClick={() => setOpen(!open)}
    >
      <GrDown /> {label}
      {open && (
        <ul css={menu(theme)}>
          {_.map(items, (text, i) => (
            <li onClick={() => onSelect(i)}>{text}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  width: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

Dropdown.defaultProps = { width: null, items: ['none'], onChange: () => {} };

export default Dropdown;
