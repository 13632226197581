/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css, useTheme } from '@emotion/react';
import dayjs from 'dayjs';
import Linkify from 'react-linkify';
import { BsDownload, BsPaperclip } from 'react-icons/bs';
import { FaCheck, FaThumbsUp } from 'react-icons/fa';
import getApiByType from 'misc/getApiByType';
import { WidthLimit } from 'ui';

const pageWrapper = (theme) => css`
  padding: 80px 15px 40px 15px;
  min-height: 100%;
  background-color: #ffffff;
  box-shadow: ${theme.shadow};
  word-break: break-all;
  line-height: 1.3;
`;

const titleText = css`
  margin-bottom: 10px;
  font-size: 18pt;
  font-weight: bold;
`;

const dateText = (theme) => css`
  margin-bottom: 30px;
  color: ${theme.colors.secondary};
`;

const videoWrapper = css`
  margin-bottom: 10px;
`;

const imageStyle = css`
  margin-bottom: 10px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;

const bodyText = css`
  margin-bottom: 30px;

  & a {
    color: #1c7ed6;
    text-decoration: none;
    transition: 0.3s color;
  }

  & a:hover {
    color: #1864ab;
  }

  & pre {
    word-break: break-all;
    white-space: pre-wrap;
  }
`;

const attachment = css`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  transition: 0.3s background-color;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

const likeButton = (theme, activated) => css`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: ${activated ? theme.colors.primaryHover : '#ffffff'};
  border: 1px solid
    ${activated ? theme.colors.primaryHover : theme.colors.primary};
  color: ${activated ? '#ffffff' : theme.colors.primary};
  cursor: pointer;
  transition: 0.3s background-color;
`;

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noreferrer">
    {text}
  </a>
);

const PostTemplate = ({
  type,
  id,
  likeCount,
  title,
  date,
  body,
  video,
  images,
  files,
  links,
  vote,
}) => {
  const theme = useTheme();
  const [count, setCount] = useState(likeCount);
  const [voteActivated, setVoteActivated] = useState(false);

  useEffect(() => {
    setCount(likeCount);
  }, [likeCount]);

  useEffect(() => {
    getApiByType(type)
      .getVoteActivated(id)
      .then((data) => {
        const { like } = data;
        setVoteActivated(like);
      });
  }, [id, type, count]);

  const download = (url) => {
    window.open(url);
  };

  const onVoteClick = async () => {
    try {
      const { like } = await getApiByType(type).voteUp(id);
      setCount(like);
    } catch (e) {
      if (e.response.status === 403) alert('로그인이 필요합니다.');
    }
  };

  return (
    <WidthLimit>
      <div css={pageWrapper(theme)}>
        {title && <h3 css={titleText}>{title}</h3>}
        {date && <div css={dateText(theme)}>{dayjs(date).fromNow()}</div>}

        {video && (
          <iframe
            css={videoWrapper}
            width="100%"
            height="300"
            src={video}
            title="YouTube video player"
          />
        )}

        {_.map(images, (image) => (
          <img css={imageStyle} src={image} />
        ))}

        {body && (
          <Linkify componentDecorator={componentDecorator}>
            <div css={bodyText}>
              <pre>{body}</pre>
            </div>
          </Linkify>
        )}

        {_.map(files, (file) => (
          <button css={attachment} onClick={() => download(file?.url)}>
            <BsDownload /> {file.filename} (
            {file.size / 1024 < 1024
              ? `${(file.size / 1024).toFixed(1)}K`
              : `${(file.size / 1024 / 1024).toFixed(2)}M`}
            )
          </button>
        ))}

        {_.map(links, (link, i) => (
          <button css={attachment} onClick={() => window.open(link)}>
            <BsPaperclip /> 외부 링크 {i + 1}
          </button>
        ))}

        {vote && (
          <button css={likeButton(theme, voteActivated)} onClick={onVoteClick}>
            {voteActivated ? (
              <>
                <FaCheck /> 좋아요 함
              </>
            ) : (
              <>
                <FaThumbsUp /> 좋아요
              </>
            )}{' '}
            ({String(count)})
          </button>
        )}
      </div>
    </WidthLimit>
  );
};

PostTemplate.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  likeCount: PropTypes.number,
  title: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  body: PropTypes.string,
  video: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.string),
  vote: PropTypes.bool,
};

PostTemplate.defaultProps = {
  likeCount: 0,
  title: null,
  date: null,
  body: null,
  video: null,
  images: null,
  files: null,
  links: null,
  vote: true,
};

export default PostTemplate;
