/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  Navigation,
  WidthLimit,
  Margin,
  AnimatedCard,
  VoteCard,
  Carousel,
} from 'ui';
import apis from 'apis';
import { css } from '@emotion/react';

import Header from './components/Header';
import Title from './components/Title';
import Rail from './components/Rail';

const h4 = css`
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 350;
  line-height: 1.4rem;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
`;

const bold = css`
  font-weight: 550;
`;
const Main = () => {
  const history = useHistory();
  const [transparent, setTransparent] = useState(true);
  const [notices, setNotices] = useState([]);
  const [movements, setMovements] = useState([]);
  const [contents, setContents] = useState([]);
  const [news, setNews] = useState([]);
  const [cheerings, setCheerings] = useState([]);

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    window.onscroll = () => {
      setTransparent(window.pageYOffset <= 10);
    };
  }, []);

  useEffect(() => {
    apis.notices.get().then((data) => setNotices(data?.results));
    apis.movements.get().then((data) => setMovements(data?.results));
    apis.contents.get().then((data) => setContents(data?.results));
    apis.news.get().then((data) => setNews(data?.results));
    apis.cheerings.get().then((data) => setCheerings(data?.results));
  }, [accessToken]);

  return (
    <>
      <Navigation transparent={transparent} noBack noLogo={transparent} />

      <Header complaints={1423} cheerings={3423} users={12342} />

      <Carousel />

      <Margin size={30} />

      <WidthLimit>
        <Title morePath="/notices">공지사항</Title>
        <Rail itemWidth={270}>
          {_.map(notices, (item) => (
            <AnimatedCard
              key={item.id}
              clickable
              title={item.title}
              description={item.body}
              date={item.created_at}
              onClick={() => history.push(`/notices/${item.id}`)}
            />
          ))}
        </Rail>

        {accessToken && (
          <>
            <Title morePath="/movements">주목 받고 있는 움직임</Title>
            <Rail itemWidth={220}>
              {_.map(movements, (item) => (
                <VoteCard
                  clickable
                  key={item.id}
                  type="movements"
                  id={item.id}
                  up={item.like_count}
                  images={item?.images}
                  title={item.title}
                  description={item.body_public}
                  date={item.created_at}
                  hideDown
                  onClick={() => history.push(`/movements/${item.id}`)}
                />
              ))}
            </Rail>
          </>
        )}

        <Title morePath="/contents">주목 받고 있는 콘텐츠</Title>
        <Rail itemWidth={220}>
          {_.map(contents, (item) => (
            <VoteCard
              clickable
              key={item.id}
              type="contents"
              id={item.id}
              up={item.like_count}
              images={item?.images}
              title={item.title}
              description={item.body}
              date={item.created_at}
              hideDown
              onClick={() => history.push(`/contents/${item.id}`)}
            />
          ))}
        </Rail>

        <Title morePath="/news">실시간 뉴스</Title>
        <Rail itemWidth={250}>
          {_.map(news, (item) => (
            <VoteCard
              clickable
              key={item.id}
              type="news"
              id={item.id}
              up={item.like_count}
              title={item.title}
              description={item.article}
              date={item.pub_date}
              hideDown
              onClick={() => window.open(item.link)}
            />
          ))}
        </Rail>

        {accessToken && (
          <>
            <Title morePath="/encourage">응원의 한마디</Title>
            <Rail itemWidth={220}>
              {_.map(cheerings, (item) => (
                <VoteCard
                  key={item.id}
                  type="cheerings"
                  id={item.id}
                  up={item.like_count}
                  down={item.unlike_count}
                  title={`인하인#${item.user}`}
                  description={item.comment}
                  date={item.created_at}
                />
              ))}
            </Rail>
          </>
        )}
        {!accessToken && (
          <>
            <div css={h4}>
              <b css={bold} onClick={() => history.push('/sign-in')}>
                로그인
              </b>{' '}
              또는{' '}
              <b css={bold} onClick={() => history.push('/sign-up')}>
                회원 가입
              </b>
              하셔서 더 많은 SAVE INHA 정보에 엑세스 하세요!
            </div>
            <Margin size={10} />
          </>
        )}
      </WidthLimit>
    </>
  );
};

export default Main;
