/** @jsxImportSource @emotion/react */
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { RiCameraFill } from 'react-icons/ri';

const wrapper = (theme, image) => css`
  height: 500px;
  background-color: #dddddd;
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  border-radius: ${theme.radius};
`;

const labelWrapper = (theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.radius};
  width: 100%;
  height: 100%;
  font-size: 11pt;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 50px;
  color: #ffffff;
`;

const ImageInput = ({ onChange }) => {
  const file = useRef();
  const theme = useTheme();
  const [image, setImage] = useState();

  const onImageChange = async () => {
    const toBase64 = (f) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    if (!file.current.files[0]) {
      setImage(null);
      onChange(null);
      return;
    }

    const base64 = await toBase64(file.current.files[0]);
    setImage(base64);

    onChange(file.current.files[0]);
  };

  return (
    <div css={wrapper(theme, image)}>
      <label css={labelWrapper(theme)} htmlFor="picture">
        <RiCameraFill />
      </label>
      <input
        type="file"
        id="picture"
        ref={file}
        hidden
        onChange={onImageChange}
      />
    </div>
  );
};

ImageInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ImageInput;
