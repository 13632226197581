/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import { FiArrowLeft, FiMenu } from 'react-icons/fi';
import { Flex, WidthLimit } from 'ui';
import { menuState } from 'atoms';

const wrapper = (theme, transparent) => css`
  padding: 15px;
  position: fixed;
  width: 100%;
  background-color: ${transparent ? 'transparent' : '#ffffff'};
  box-shadow: ${transparent ? 'none' : theme.shadow};
  font-size: 15pt;
  color: ${transparent ? '#ffffff' : '#000000'};
  transition: 0.3s color, 0.3s box-shadow, 0.3s background-color;
  z-index: 998;

  & button {
    color: ${transparent ? '#ffffff' : '#000000'};
  }
`;

const logo = (theme) => css`
  margin-top: -4px;
  font-weight: bold;
  transition: 0.3s color;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }
`;

const button = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 17pt;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s color;
`;

const Navigation = ({ transparent, noBack, noLogo }) => {
  const history = useHistory();
  const theme = useTheme();
  const [, setMenu] = useRecoilState(menuState);

  return (
    <div css={wrapper(theme, transparent)}>
      <WidthLimit>
        <Flex justify="space-between" align="center">
          <button css={button} onClick={() => history.goBack()}>
            {noBack || <FiArrowLeft />}
          </button>
          <div css={logo(theme)} onClick={() => history.push('/')}>
            {noLogo || 'SAVE INHA'}
          </div>
          <button css={button} onClick={() => setMenu(true)}>
            <FiMenu />
          </button>
        </Flex>
      </WidthLimit>
    </div>
  );
};

Navigation.propTypes = {
  transparent: PropTypes.bool,
  noBack: PropTypes.bool,
  noLogo: PropTypes.bool,
};

Navigation.defaultProps = {
  transparent: false,
  noBack: false,
  noLogo: false,
};

export default Navigation;
