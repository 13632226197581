/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { css, keyframes, useTheme } from '@emotion/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { menuState } from 'atoms';

const dimmerOpen = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0);
  }

  100% {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
  }
`;

const dimmerClose = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
  }

  100% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0);
  }
`;

const menuOpen = keyframes`
  0% {
    transform: translateX(-200px);
  }

  100% {
    transform: translateX(0);
  }
`;

const menuClose = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-200px);
  }
`;

const dimmer = (open, display) => css`
  display: ${display};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  animation-name: ${open ? dimmerOpen : dimmerClose};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 999;
`;

const wrapper = (theme, open) => css`
  position: absolute;
  padding: 15px 0;
  height: 100%;
  width: 200px;
  overflow-y: scroll;
  background-color: #ffffff;
  box-shadow: ${theme.shadow};
  animation-name: ${open ? menuOpen : menuClose};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
`;

const title = css`
  padding: 20px 15px;
  font-size: 10pt;
  font-weight: bold;
`;

const item = css`
  padding: 10px 15px;
  cursor: pointer;

  &:last-child {
    padding-bottom: 20px;
  }
`;

const divider = css`
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

const Menu = () => {
  const history = useHistory();
  const theme = useTheme();
  const [menu, setMenu] = useRecoilState(menuState);
  const [display, setDisplay] = useState('none');

  const accessToken = localStorage.getItem('accessToken');

  const onOutsideClick = () => {
    setMenu(false);
  };

  const onMenuWrapperClick = (e) => {
    e.stopPropagation();
  };

  const onMenuClick = (path) => {
    history.push(path);
    setMenu(false);
  };

  useEffect(() => {
    if (!menu)
      setTimeout(() => {
        setDisplay('none');
      }, 300);
    else {
      setDisplay('block');
    }
  }, [menu]);

  const logOut = () => {
    localStorage.clear();
    alert('로그아웃 되었습니다.');
    window.location.href = '/';
  };

  return (
    <div css={dimmer(menu, display)} onClick={onOutsideClick}>
      <div css={wrapper(theme, menu)} onClick={onMenuWrapperClick}>
        <div css={title}> MENU </div>
        <ul>
          <li css={item} onClick={() => onMenuClick('/notices')}>
            공지사항
          </li>
          <li css={item} onClick={() => onMenuClick('/movements')}>
            주목 받고 있는 움직임
          </li>
          <li css={item} onClick={() => onMenuClick('/contents')}>
            주목 받고 있는 콘텐츠
          </li>
          <li css={item} onClick={() => onMenuClick('/news')}>
            실시간 뉴스
          </li>
          <li css={item} onClick={() => onMenuClick('/encourage')}>
            응원의 한마디
          </li>
        </ul>

        <div css={divider} />
        <div css={title}> SAVE INHA </div>
        <ul>
          <li css={item} onClick={() => onMenuClick('/about')}>
            서비스 소개
          </li>
          <li css={item} onClick={() => onMenuClick('/terms')}>
            이용 약관
          </li>
          <li css={item} onClick={() => onMenuClick('/privacy')}>
            개인정보 처리방침
          </li>
        </ul>

        <div css={divider} />
        <div css={title}> CONTACT </div>
        <ul>
          <CopyToClipboard
            text="webmaster@saveinha.com"
            onCopy={() => alert('이메일 주소가 복사되었습니다.')}
          >
            <li css={item}>기술 문의</li>
          </CopyToClipboard>

          <CopyToClipboard
            text="contact@saveinha.com"
            onCopy={() => alert('이메일 주소가 복사되었습니다.')}
          >
            <li css={item}>제보 및 기타 문의</li>
          </CopyToClipboard>
        </ul>

        <div css={divider} />
        <div css={title}> USER </div>
        <ul>
          {accessToken ? (
            <li css={item} onClick={logOut}>
              로그아웃
            </li>
          ) : (
            <>
              <li css={item} onClick={() => onMenuClick('/sign-in')}>
                로그인
              </li>
              <li css={item} onClick={() => onMenuClick('/sign-up')}>
                회원가입
              </li>
              <li
                css={item}
                onClick={() => onMenuClick('/reset-password/request')}
              >
                비밀번호 찾기
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
