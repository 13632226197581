import axios from 'axios';

const get = async () =>
  (
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/main`,
    })
  ).data;

export default {
  get,
};
