/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Navigation, PostTemplate } from 'ui';
import apis from 'apis';

const Notice = () => {
  const params = useParams();
  const [post, setPost] = useState();

  useEffect(() => {
    apis.news.getOnce(params.id).then((data) => {
      setPost(data);
    });
  }, [params.id]);

  return (
    <>
      <Navigation />

      <PostTemplate
        type="news"
        id={post?.id}
        likeCount={post?.like_count}
        title={post?.title}
        date={post?.pub_date}
        body={post?.article}
        links={[post?.link]}
      />
    </>
  );
};

export default Notice;
