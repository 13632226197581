import axios from 'axios';

const get = async (page = 1, sort = 'date') =>
  (
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/tweet?page=${page}&sort=${sort}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
  ).data;

const post = async (comment) =>
  (
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/tweet`,
      data: {
        comment,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
  ).data;

const getVoteActivated = async (id) =>
  (
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/tweet/like/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
  ).data;

const voteUp = async (id) =>
  (
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/tweet/like/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
  ).data;

const voteDown = async (id) =>
  (
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/tweet/unlike/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
  ).data;

export default {
  get,
  post,
  getVoteActivated,
  voteUp,
  voteDown,
};
