import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Navigation, WidthLimit, Margin, TextField, Button } from 'ui';
import apis from 'apis';

const PasswordReset = () => {
  const history = useHistory();

  const [email, setEmail] = useState('');

  const onSubmit = async () => {
    try {
      await apis.auth.passwordResetRequest(email);
    } catch (e) {
      alert(e);
      return;
    }

    alert(
      `비밀번호 재설정 이메일을 ${email} 로 전송했습니다. 메일함을 확인해주세요.`,
    );
    history.push('/');
  };

  return (
    <>
      <Navigation />

      <Margin size={80} />

      <WidthLimit>
        <TextField
          label="이메일"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Margin size={20} />

        <Button onClick={onSubmit} disabled={!email}>
          초기화 메일 전송
        </Button>
      </WidthLimit>
    </>
  );
};

export default PasswordReset;
