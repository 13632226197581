/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom';
import { Flex, Button, Navigation } from 'ui';
import { css } from '@emotion/react';

const container = css`
  padding: 3rem;
`;

const h3 = css`
  width: 80%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 auto;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid #e1e1e1;
  text-align: center;
  padding: 2rem 0 1.5rem;
`;

const h4 = css`
  font-size: 1rem;
  line-height: 1.3em;
  font-weight: 600;
  line-height: 2rem;
`;

const h5 = css`
  font-size: 1rem;
  line-height: 1.3em;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 16rem;
`;

const Signup = () => {
  const history = useHistory();
  return (
    <>
      <Navigation />
      <div css={container}>
        <div css={h3}>회원가입</div>

        <div css={h5}>
          세이브인하는 인하대학교 재학생 및 졸업생만 가입이 가능합니다. 다음과
          같은 방법으로 인증이 필요합니다.
        </div>

        <div css={h4}>어떤 방식으로 인증하시겠습니까?</div>
        <br />

        <Flex>
          <Button onClick={() => history.push('/join1')}>
            이메일로 인증하기
          </Button>
        </Flex>
      </div>
    </>
  );
};

export default Signup;
