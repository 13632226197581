import { useHistory } from 'react-router-dom';
import { Navigation, CardList, Margin } from 'ui';

const Movements = () => {
  const history = useHistory();

  const onClick = (item) => {
    history.push(`/movements/${item.id}`);
  };

  return (
    <>
      <Navigation />

      <Margin size={80} />

      <CardList
        title="주목 받고 있는 움직임"
        subtitle="인하를 구하는 작지만 큰 움직임"
        descriptionField="body_public"
        type="movements"
        onClick={onClick}
      />

      <Margin size={110} />
    </>
  );
};

export default Movements;
