import { useHistory } from 'react-router-dom';
import { Navigation, CardList, Margin } from 'ui';

const Notice = () => {
  const history = useHistory();

  const onClick = (item) => {
    history.push(`/notices/${item.id}`);
  };

  return (
    <>
      <Navigation />

      <Margin size={80} />

      <CardList
        title="공지사항"
        subtitle="SAVE INHA 의 소식을 전달드립니다."
        descriptionField="body"
        type="notices"
        vote={false}
        onClick={onClick}
      />

      <Margin size={110} />
    </>
  );
};

export default Notice;
