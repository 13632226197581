/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const wrapper = css`
  margin: 0 auto;
  padding: 0 10px;
  max-width: 575.98px;
`;

const WidthLimit = ({ children }) => <div css={wrapper}>{children}</div>;

WidthLimit.propTypes = {
  children: PropTypes.node,
};

WidthLimit.defaultProps = {
  children: null,
};

export default WidthLimit;
