/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const margin = (size) => css`
  height: ${size}px;
`;

const Margin = ({ size }) => <div css={margin(size)} />;

Margin.propTypes = {
  size: PropTypes.number,
};

Margin.defaultProps = {
  size: 10,
};

export default Margin;
