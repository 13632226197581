import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks';
import apis from 'apis';

const EmailVerification = () => {
  const query = useQuery();

  const email = query.get('email');
  const token = query.get('token');
  const history = useHistory();

  useEffect(() => {
    apis.auth
      .emailVerificate(email, token)
      .then((data) => {
        const { access, refresh } = data;
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);

        alert('메일 인증이 완료되어 성공적으로 가입되었습니다.');
        history.push('/');
      })
      .catch(() => {
        alert('이미 확인된 메일 주소이거나, 알 수 없는 오류가 발생하였습니다.');
        history.push('/');
      });
  });
  return <></>;
};

export default EmailVerification;
