/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { css } from '@emotion/react';
import Slider from 'react-slick';
import apis from 'apis';
import { WidthLimit, Margin } from 'ui';

const imageWrapper = (image) => css`
  height: 200px;
  background-color: #cccccc;
  background-image: url(${image});
  background-size: cover;
  background-position: center;
`;

const dimmer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  font-size: 16pt;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
  text-align: center;
  line-height: 1.5;
  cursor: pointer;
`;

const carouselWrapper = css``;

const arrow = css`
  @media (max-width: 575.98px) {
    display: none !important;
  }

  &::before {
    color: #000000;
  }
`;

const Arrow = ({ ...props }) => <div css={arrow} {...props} />;

const Carousel = () => {
  const [items, setItems] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
  };

  useEffect(() => {
    apis.carousel.get().then((data) => {
      setItems(data?.results);
    });
  }, []);

  const onClick = (url) => {
    window.location.href = url;
  };

  return (
    <WidthLimit>
      <Margin size={30} />
      <div css={carouselWrapper}>
        <Slider {...settings}>
          {_.map(items, (item) => (
            <div key={item.key} css={imageWrapper(item.image)}>
              <div css={dimmer} onClick={() => onClick(item.url)}>
                {item.title}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <Margin size={40} />
    </WidthLimit>
  );
};

export default Carousel;
